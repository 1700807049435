export default function About() {
  return (
    <>
      <div className="container-fluid header">
        <div className="p-2">
          <img src="/images/curve-line-01.svg" className="header-curve-line" alt="curve-line" />
        </div>
        <div className="header-heading">About Us</div>
        <div className="header-slogan">
          Every celebration brings us joy and happiness and countless memories.
          <br />
          We work hard to make you smile more.
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-7 aboutus-section">
            <div className="aboutus-section-heading">
              We'll&nbsp;make&nbsp;your next
              <br />
              celebration very special!
            </div>
            <div className="aboutus-section-description">
              It's simple. You have an event to plan and we have the solution. As the premier event planning company in New York City, we know that it's not “one size fits all.”
              <br />
              <br />
              Each event and client is unique and we believe our services should be as well. We know that it should be “Can I hire a planner?” not “Can I afford one?”.
            </div>
            <a href="https://forms.gle/SqStG8uJ2AYedMKe6" target="_blank" rel="noreferrer">
              <div className="btn rounded-pill aboutus-section-button">
                <i className="fas fa-pen"></i> Get in touch
              </div>
            </a>
          </div>
          <div className="col-sm-5 aboutus-photos-box">
            <div className="row m-0">
              <div className="col-6 col-sm-6 aboutus-photos">
                <img src="/images/celebration1.jpg" className="aboutus-images" alt="celebration1" />
              </div>
              <div className="col-6 col-sm-6 aboutus-photos">
                <img src="/images/celebration2.jpg" className="aboutus-images" alt="celebration2" />
              </div>
              <div className="col-6 col-sm-6 aboutus-photos">
                <img src="/images/celebration3.jpg" className="aboutus-images" alt="celebration3" />
              </div>
              <div className="col-6 col-sm-6 aboutus-photos">
                <img src="/images/celebration4.jpg" className="aboutus-images" alt="celebration4" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid four-reason">Four reasons why you should choose us.</div>

      <div className="container-fluid">
        <div className="row four-reason-section-row">
          <div className="col-12 col-sm-6 four-reason-section-col1">
            <img src="/images/Asset%203.svg" className="col-6 four-reason-section-col1-image" alt="icon" />
            <div className="four-reason-section-col1-title">Friendly Team</div>
            <div className="four-reason-section-col1-description">Our Team members are a combination of fun and creativity. They will provide you with the best creative ideas that you could find and coordinate with you like a friend very easily when required.</div>
          </div>
          <div className="col-12 col-sm-6 four-reason-section-col2">
            <img src="/images/chooseus-image-1.jpg" className="four-reason-section-col2-image" alt="icon" />
          </div>
        </div>
        <div className="row four-reason-section-row">
          <div className="col-12 order-12 col-sm-6 order-sm-first four-reason-section-col2">
            <img src="/images/chooseus-image-2.jpg" className="four-reason-section-col2-image" alt="icon" />
          </div>
          <div className="col-12 col-sm-6 four-reason-section-col1">
            <img src="/images/Asset%204.svg" className="col-6 four-reason-section-col1-image" alt="icon" />
            <div className="four-reason-section-col1-title">Unforgettable Time</div>
            <div className="four-reason-section-col1-description">We know that every event matters a lot to you that's why we work hard and give our best to make them more beutiful and give you such unforgettable time and memories.</div>
          </div>
        </div>
        <div className="row four-reason-section-row">
          <div className="col-12 col-sm-6 four-reason-section-col1">
            <img src="/images/Asset%202.svg" className="col-6 four-reason-section-col1-image" alt="icon" />
            <div className="four-reason-section-col1-title">Unique Scenarios</div>
            <div className="four-reason-section-col1-description">We come with innovative and unique ideas that you have never heard off. Our team creates such events that they are always unique from the previous one.</div>
          </div>
          <div className="col-12 col-sm-6 four-reason-section-col2">
            <img src="/images/chooseus-image-3.jpg" className="four-reason-section-col2-image" alt="icon" />
          </div>
        </div>
        <div className="row four-reason-section-row">
          <div className="col-12 col-12 order-12 col-sm-6 order-sm-first four-reason-section-col2">
            <img src="/images/chooseus-image-4.jpg" className="four-reason-section-col2-image" alt="icon" />
          </div>
          <div className="col-12 col-sm-6 four-reason-section-col1">
            <img src="/images/Asset%201.svg" className="col-6 four-reason-section-col1-image" alt="icon" />
            <div className="four-reason-section-col1-title">Perfect Venues</div>
            <div className="four-reason-section-col1-description">We arrange perfect venues according to your needs and mostly in accordance of your budget. We make sure that you get best according to your needs.</div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row aboutus-section2">
          <div className="col-md-6 aboutus-section2-col1">
            Our goal
            <br />
            is <span style={{ color: "orangered" }}>organizing</span>
            <br />
            efficient event
          </div>
          <div className="col-md-6 aboutus-section2-col2">
            <div className="about-us-text-1">PRJ Event Management was created by pairing together our passion for business to organize great events.</div>
            <div className="about-us-text-2">We bring a fresh, unique approach to the event management industry. Our team understands that a properly executed event can be leveraged to support an organization's strategic vision, incorporated into a company's marketing plan, or used to build networks and client loyalty that are really priceless</div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0">
        <div className="aboutus-team-member">
          <div className="aboutus-team-member-col2-line1">Our Team</div>
          <div className="aboutus-team-member-col2-line2">Meet our experts</div>
          <div className="row aboutus-team-member-col1 m-0">
            <div className="row m-0">
              <div className="col-6 col-sm-3 p-3">
                <div className="aboutus-team-member-imagebox">
                  <div style={{ overflow: "hidden" }}>
                    <img src="/images/team-member1.png" className="aboutus-team-member-image" alt="team-member" />
                  </div>
                  <div className="aboutus-team-member-position">Lead Planner</div>
                  <div className="aboutus-team-member-name">Sodan Meena</div>
                </div>
              </div>
              <div className="col-6 col-sm-3 p-3">
                <div className="aboutus-team-member-imagebox">
                  <div style={{ overflow: "hidden" }}>
                    <img src="/images/team-member9.png" className="aboutus-team-member-image" alt="team-member" />
                  </div>
                  <div className="aboutus-team-member-position">Event Coordinator</div>
                  <div className="aboutus-team-member-name">Kajal Mehto</div>
                </div>
              </div>
              <div className="col-6 col-sm-3 p-3">
                <div className="aboutus-team-member-imagebox">
                  <div style={{ overflow: "hidden" }}>
                    <img src="/images/team-member6.png" className="aboutus-team-member-image" alt="team-member" />
                  </div>
                  <div className="aboutus-team-member-position">Event Coordinator</div>
                  <div className="aboutus-team-member-name">Roshan Pandey</div>
                </div>
              </div>
              <div className="col-6 col-sm-3 p-3">
                <div className="aboutus-team-member-imagebox">
                  <div style={{ overflow: "hidden" }}>
                    <img src="/images/team-member5.png" className="aboutus-team-member-image" alt="team-member" />
                  </div>
                  <div className="aboutus-team-member-position">Event Coordinator</div>
                  <div className="aboutus-team-member-name">Vijay Patel</div>
                </div>
              </div>
              <div className="col-6 col-sm-3 p-3">
                <div className="aboutus-team-member-imagebox">
                  <div style={{ overflow: "hidden" }}>
                    <img src="/images/team-member7.png" className="aboutus-team-member-image" alt="team-member" />
                  </div>
                  <div className="aboutus-team-member-position">Event Planner</div>
                  <div className="aboutus-team-member-name">Pushpendra Kushwah</div>
                </div>
              </div>
              <div className="col-6 col-sm-3 p-3">
                <div className="aboutus-team-member-imagebox">
                  <div style={{ overflow: "hidden" }}>
                    <img src="/images/team-member8.png" className="aboutus-team-member-image" alt="team-member" />
                  </div>
                  <div className="aboutus-team-member-position">Event Plannner</div>
                  <div className="aboutus-team-member-name">Suraj Goswami</div>
                </div>
              </div>
              <div className="col-6 col-sm-3 p-3">
                <div className="aboutus-team-member-imagebox">
                  <div style={{ overflow: "hidden" }}>
                    <img src="/images/team-member4.png" className="aboutus-team-member-image" alt="team-member" />
                  </div>
                  <div className="aboutus-team-member-position">Lead PLanner</div>
                  <div className="aboutus-team-member-name">Purushottam Jatav</div>
                </div>
              </div>
              <div className="col-6 col-sm-3 p-3">
                <div className="aboutus-team-member-imagebox">
                  <div style={{ overflow: "hidden" }}>
                    <img src="/images/team-member10.png" className="aboutus-team-member-image" alt="team-member" />
                  </div>
                  <div className="aboutus-team-member-position">Event Coordinator</div>
                  <div className="aboutus-team-member-name">Neetu Maheshwari</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid use-our-resources">
        <div className="row use-our-resources-container">
          <div className="col-sm-6 use-our-resources-headingbox">
            <div className="use-our-resources-heading">
              Use our
              <br />
              resources,
              <br />
              not yours
            </div>
            <div className="use-our-resources-title">PRJ Events can:</div>
          </div>
          <div className="col-sm-6 use-our-resources-listbox">
            <ul className="use-our-resources-list">
              <li>
                <i className="fas fa-check"></i> &#160; Help you find the perfect venue for FREE
              </li>
              <li>
                <i className="fas fa-check"></i> &#160; Help you plan the event
              </li>
              <li>
                <i className="fas fa-check"></i> &#160; Connect you with the best vendors
              </li>
              <li>
                <i className="fas fa-check"></i> &#160; Produce any aspect of the event
              </li>
            </ul>
            <a href="https://forms.gle/SqStG8uJ2AYedMKe6" target="_blank" rel="noreferrer">
              <div className="btn rounded-pill use-our-resources-button">Online Request</div>
            </a>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row founders-messagebox">
          <div className="col-sm-6 founders-messageboxcol1">
            <div className="founders-message-heading">
              Founder's <br />
              message
            </div>
            <div className="founders-message">You have an event to plan and we have the solution. As the premier event planning company, we know that it's not “one size fits all.” Each event and client is unique and we believe our services should be as well.</div>
            <div className="founders-name">Richa Chakarvarti</div>
          </div>
          <div className="col-12 col-sm-6 founders-imagebox">
            <img src="/images/owner-image.jpg" className="founders-image" alt="owner" />
          </div>
        </div>
      </div>
    </>
  );
}
