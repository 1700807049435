export default function HelpingMembers() {
  return (
    <>
      <div className="they-can-help-you">They Can Help You</div>
      <div className="they-can-help-you-description">Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</div>
      <div className="row p-0 m-0 pb-5">
        <div className="col-sm-4 allservices-helpingmember1-mainbox-padding">
          <div className="allservices-helpingmember-mainbox">
            <div className="allservices-helpingmember-imagebox">
              <img src="/images/team-member1.png" className="allservices-helpingmember-image img-fluid" alt="team-member" />
              <div className="allservices-helpingmember-iconbox">
                <a href="https://www.facebook.com/sodan.meena.925" target="_blank" rel="noreferrer">
                  <i className="fab fa-facebook-f allservices-helpingmember-iconcontainer" style={{ padding: "4.5% 5.7%" }}></i>
                </a>
                <a href="https://www.instagram.com/sodan_singh_meena/" target="_blank" rel="noreferrer">
                  <i className="fab fa-instagram allservices-helpingmember-iconcontainer"></i>
                </a>
              </div>
            </div>
            <div className="allservices-helpingmember-name">Sodan Meena</div>
            <div className="allservices-helpingmember-position">Lead Planner</div>
          </div>
        </div>
        <div className="col-sm-4 allservices-helpingmember2-mainbox-padding">
          <div className="allservices-helpingmember-mainbox">
            <div className="allservices-helpingmember-imagebox">
              <img src="../../images/team-member9.png" className="allservices-helpingmember-image" alt="team-member" />
              <div className="allservices-helpingmember-iconbox">
                <a href="">
                  <i className="fab fa-facebook-f allservices-helpingmember-iconcontainer" style={{ padding: "4.5% 5.7%" }}></i>
                </a>
                <a href="">
                  <i className="fab fa-instagram allservices-helpingmember-iconcontainer"></i>
                </a>
              </div>
            </div>
            <div className="allservices-helpingmember-name">Kajal Mehto</div>
            <div className="allservices-helpingmember-position">Design Expert</div>
          </div>
        </div>
        <div className="col-sm-4 allservices-helpingmember3-mainbox-padding">
          <div className="allservices-helpingmember-mainbox">
            <div className="allservices-helpingmember-imagebox">
              <img src="/images/team-member4.png" className="allservices-helpingmember-image" alt="team-member" />
              <div className="allservices-helpingmember-iconbox">
                <a href="">
                  <i className="fab fa-facebook-f allservices-helpingmember-iconcontainer" style={{ padding: "4.5% 5.7%" }}></i>
                </a>
                <a href="">
                  <i className="fab fa-instagram allservices-helpingmember-iconcontainer"></i>
                </a>
              </div>
            </div>
            <div className="allservices-helpingmember-name">Suraj Goswami</div>
            <div className="allservices-helpingmember-position">Planner</div>
          </div>
        </div>
      </div>
      <a href="https://forms.gle/SqStG8uJ2AYedMKe6" target="_blank" rel="noreferrer" className="btn rounded-pill allservices-online-request-button">
        Online Request
      </a>
    </>
  );
}
